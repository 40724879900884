<template>
  <div
    ref="trustbox"
    class="trustpilot-widget"
    data-businessunit-id="50664da000006400051c4308"
    data-locale="de-DE"
    data-style-height="85px"
    data-style-width="130px"
    data-template-id="53aa8807dec7e10d38f59f32"
    data-theme="light"
  >
    <NuxtLink
      to="https://de.trustpilot.com/review/pflege.de"
      external
      rel="noopener"
      target="_blank"
    >
      Trustpilot
    </NuxtLink>
  </div>
</template>

<script setup>
const trustbox = ref('')

onMounted(() => {
  const script = document.createElement('script')
  script.type = 'text/javascript'
  script.async = true
  script.src = '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js'
  document.head.appendChild(script)

  script.onload = () => {
    window.Trustpilot.loadFromElement(trustbox.value)
  }
})
</script>
